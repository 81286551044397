import { useLocation } from "@reach/router"
import queryString from "query-string"
import React, { useEffect, useState } from "react"

import Breadcrumbs from "../componets/Breadcrumbs/Breadcrumbs"
import Footer from "../componets/Footer/Footer"
import Header from "../componets/Header/Header"
import ResultatRecherche from "../componets/ResultatRecherche/ResultatRecherche"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import {
  slugify,
  stripTags,
  translateSponsoredTraining,
} from "../services/helpers"

import "../pages/globals.css"
import "bootstrap/dist/css/bootstrap.min.css"

export default function Region({ pageContext }) {
  const location = useLocation()
  const queries = queryString.parse(location.search)
  const {
    item,
    agencies,
    trainings,
    types,
    regions,
    cities,
    levels,
    sectors,
    publicAdmis,
    mobilizableDevices,
    logo,
  } = pageContext
  const metas = {
    title:
      "Annonces formation, alternance et stage à La Réunion, Drom Com, Océan Indien",
    metatitle:
      "Offres de formation à La Réunion, dans les Drom Com, l'Océan Indien - Se Former.re",
    description:
      "Toutes les annonces de formation et offre de stage, alternance dans tous les domaines, secteurs et pour tous les niveaux.",
    keywords:
      "formation, stage, La Réunion, drom, com, annonce, formation à distance, formation en continu, professionnelle, en alternance, Mayotte, Maurice, Océan Indien, Antilles, Madagascar",
  }

  const [allMetiersVises, setAllMetiersVises] = useState([])

  useEffect(() => {
    if (!allMetiersVises.length) {
      const allSelectMetiersVises = []
      if (trainings.length) {
        trainings.map(item => {
          if (
            item.target_job &&
            allSelectMetiersVises.filter(
              e => e.name === stripTags(item.target_job)
            ).length == 0
          ) {
            allSelectMetiersVises.push({
              id: item.id,
              name: stripTags(item.target_job),
            })
          }
        })
      }

      if (allSelectMetiersVises.length) {
        setAllMetiersVises(allSelectMetiersVises)
      }
    }
  }, [allMetiersVises])

  const [sponsoredTrainings, setSponsoredTrainings] = useState([])
  useEffect(() => {
    if (trainings && Array.isArray(trainings)) {
      const sTraining = trainings.filter(
        item =>
          item.db_article_option_paids.filter(
            option_paids =>
              option_paids.db_option &&
              option_paids.db_option.db_option_type.value === "sponsorised"
          ).length
      )
      setSponsoredTrainings(sTraining)
    }
  }, [trainings])

  return (
    <div className="pageresultatrecherche">
      <SiteMetadata metas={metas} />
      <Header logo={logo} />
      <Breadcrumbs />
      <ResultatRecherche
        currentRegion={slugify(item.name)}
        queries={queries}
        agencies={agencies}
        trainings={trainings}
        sponsoredTrainings={sponsoredTrainings}
        types={types}
        regions={regions}
        cities={cities}
        levels={levels}
        sectors={sectors}
        publicAdmis={publicAdmis}
        mobilizableDevices={mobilizableDevices}
        allMetiersVises={allMetiersVises}
      />
      <Footer />
    </div>
  )
}
